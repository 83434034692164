import axios, { AxiosError } from 'axios';
import { set } from 'lodash';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { NotFoundView, View403 } from 'src/sections/error';

interface QueryParams {
  t: string;
  gid: string;
  sid: string;
  lang: 'en' | 'zh';
}

const baseUrl = process.env.REACT_APP_API_URL as string;
const hashGameUrl = process.env.REACT_APP_HASH_GAME_URL || '';
const cryptoGameUrl = process.env.REACT_APP_CRYPTO_GAME_URL || '';
const boGameUrl = process.env.REACT_APP_BO_GAME_URL || '';

const mapGameUrl: { [key: string]: string } = {
  HASH_GAME: hashGameUrl,
  GAME_HASH: hashGameUrl,
  H_BS: hashGameUrl, // still point to v1
  H_BP: process.env.REACT_APP_HASH_GAME_URL ?? '',
  H_OE: process.env.REACT_APP_HASH_GAME_URL ?? '',
  H_NIUNIU: process.env.REACT_APP_HASH_GAME_URL ?? '',
  H_LUCKY: process.env.REACT_APP_HASH_GAME_URL ?? '',
  C_FUTURE: cryptoGameUrl,
  C_BO: boGameUrl,
  H_ROCKET: process.env.REACT_APP_HASH_GAME_URL ?? '',
  H_TSHOW: process.env.REACT_APP_HASH_GAME_URL ?? '',
  H_HILO: process.env.REACT_APP_HASH_GAME_URL ?? '',
  H_BINGO: process.env.REACT_APP_HASH_GAME_URL ?? '',
  H_PLINKO: process.env.REACT_APP_HASH_GAME_URL ?? '',
};

function getQueryParmas(params: URLSearchParams): QueryParams {
  const result: any = {};
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result as QueryParams;
}

function useOrderDetail() {
  const [query] = useSearchParams();
  const { t, ...params } = getQueryParmas(query);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<any>();

  useEffect(() => {
    const url = `${baseUrl}/portal/agency/order-detail`;
    axios
      .get(url, {
        params,
        headers: {
          Authorization: `Bearer ${t}`,
        },
      })
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { isLoading, error, data };
}

async function _postMessage(
  ref: RefObject<HTMLIFrameElement>,
  data: any,
  origin: string,
  timeout = 100
) {
  return new Promise((resolve, reject) => {
    let isCompleted = false;
    window.addEventListener(
      'message',
      (e: MessageEvent) => {
        if (e.origin === origin) {
          if (!isCompleted) {
            isCompleted = true;
            resolve(e.data);
          }
        }
      },
      false
    );

    setTimeout(() => {
      if (!isCompleted) {
        isCompleted = true;
        reject(new Error('Timeout'));
      }
    }, timeout);

    ref.current?.contentWindow?.postMessage(data, origin);
  });
}

async function postMessage(
  ref: RefObject<HTMLIFrameElement>,
  data: any,
  origin: string,
  retry = 10
) {
  try {
    const result = await _postMessage(ref, data, origin);
    return result;
  } catch (error) {
    if (retry > 0) return postMessage(ref, data, origin, retry - 1);
    throw error;
  }
}

function View() {
  const [query] = useSearchParams();
  const { lang, gid } = getQueryParmas(query);

  const { data, isLoading, error } = useOrderDetail();
  const gameUrl = mapGameUrl[gid];
  const url = `${gameUrl}/bet-detail?lang=${lang}&from=${encodeURIComponent(location.origin)}`;
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    const winAmount = data?.winAmount;
    if (winAmount) set(data, 'win_amount', winAmount);
    const origin = new URL(gameUrl).origin;
    postMessage(ref, data, origin).then(console.info).catch(console.warn);
  }, [data, gameUrl]);

  if (!isLoading && error) {
    return <ErrorPage error={error} />;
  }

  return (
    <iframe
      id="iframe1"
      title="Bet Detail"
      src={url}
      ref={ref}
      allow="clipboard-read; clipboard-write"
      style={{ borderStyle: 'none', width: '100vw', height: '100vh' }}
    />
  );
}

function ErrorPage({ error }: { error: Error }) {
  const code = error instanceof AxiosError ? error.response?.status || 404 : 404;
  if ([401, 403].includes(code)) return <View403 />;
  return <NotFoundView />;
}

export default View;
