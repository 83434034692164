import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import ButtonBase from '@mui/material/ButtonBase';
import { useColorScheme, useTheme } from '@mui/material/styles';

import { DashboardContent } from 'src/layouts/dashboard/main';
import { _mock } from 'src/_mock';
import { Iconify } from 'src/components/iconify';
import { ProfileCover } from 'src/sections/user/profile-cover';
import { Image } from '../../../components/image';
// import { varAlpha } from '../../../theme/styles';
import { useSettingsContext } from '../../../components/settings';
import { fNumber } from '../../../utils/format-number';
import { stringifyUrl } from 'query-string';
import { useTranslation } from 'react-i18next';
import { UserWallet } from 'src/types/user';
import { useTranslate } from '../../../locales';
import { mapCountryCodeToLang, mapLangCodeToCountryCode } from '../../../utils/param';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogContentText } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { FaChevronLeft } from 'react-icons/fa';
import { IframeCommunication, iframeCon, isLoadedByIframe } from 'src/locales/utils/iframe';
import { IframeCommunicationEvent } from '.';

enum GameID {
  C_FUTURE = 'C_FUTURE',
  C_BO = 'C_BO',
  GAME_HASH = 'GAME_HASH',
  H_BP = 'H_BP',
  H_OE = 'H_OE',
  H_NIUNIU = 'H_NIUNIU',
  H_LUCKY = 'H_LUCKY',
  H_BS = 'H_BS',
  H_ROCKET = 'H_ROCKET',
  H_TSHOW = 'H_TSHOW',
  H_HILO = 'H_HILO',
  H_BINGO = 'H_BINGO',
  H_PLINKO = 'H_PLINKO',
}
const GameUrlMap = {
  [GameID.GAME_HASH]: process.env.REACT_APP_HASH_GAME_URL || '',
  [GameID.H_BS]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_BS',
  [GameID.H_BP]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_BP',
  [GameID.H_OE]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_OE',
  [GameID.H_NIUNIU]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_NIUNIU',
  [GameID.H_LUCKY]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_LUCKY',
  [GameID.H_ROCKET]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_ROCKET',
  [GameID.H_TSHOW]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_TSHOW',
  [GameID.H_HILO]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_HILO',
  [GameID.H_BINGO]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_BINGO',
  [GameID.H_PLINKO]: (process.env.REACT_APP_HASH_GAME_URL || '') + '/H_PLINKO',
  [GameID.C_FUTURE]: (process.env.REACT_APP_CRYPTO_GAME_URL || '') + '/future/BTC_USDT',
  [GameID.C_BO]: (process.env.REACT_APP_BO_GAME_URL || '') + '/option/BTC_USDT',
};
const GameBannerMap: Record<string, Record<string, string>> = {
  en: {
    [GameID.H_BS]: 'en_bigsmall.webp',
    [GameID.H_BP]: 'en_banker-player.webp',
    [GameID.H_OE]: 'en_oddeven.webp',
    [GameID.H_NIUNIU]: 'en_niuniu.webp',
    [GameID.H_LUCKY]: 'en_lucky.webp',
    [GameID.H_ROCKET]: 'en_rocket.jpg',
    [GameID.H_TSHOW]: 'en_tshow.jpg',
    [GameID.H_HILO]: 'en_hilo.jpg',
    [GameID.H_BINGO]: 'en_bingo.jpg',
    [GameID.H_PLINKO]: 'en_plinko.jpg',
    [GameID.C_FUTURE]: 'en_future.webp',
    [GameID.C_BO]: 'en_option.webp',
  },
  zh: {
    [GameID.H_BS]: 'zh_bigsmall.webp',
    [GameID.H_BP]: 'zh_banker-player.webp',
    [GameID.H_OE]: 'zh_oddeven.webp',
    [GameID.H_NIUNIU]: 'zh_niuniu.webp',
    [GameID.H_LUCKY]: 'zh_lucky.webp',
    [GameID.C_FUTURE]: 'zh_future.webp',
    [GameID.C_BO]: 'zh_option.webp',
  },
};
function getGameBannerUrl_old(gid: GameID, lang: string): string {
  const pref = (lang ?? 'en') + '_';
  // @ts-ignore
  return GameBannerMap[gid]?.replace('[prefix]', pref);
}
function getGameBannerUrl(gid: GameID, lang: string): string {
  const fileName = (GameBannerMap[lang] ?? GameBannerMap.en)[gid] ?? GameBannerMap.en[gid];
  return '/assets/images/game_banner/' + fileName;
}

type Game = {
  id: string;
  title: string;
  category?: string;
  imageUrl: string;
  appUrl: string;
  active: boolean;
};

const GAME_ACTIVE_STATUS = 1;

export function LobbyView(props: {
  loading: boolean;
  loadingBalance: boolean;
  colorScheme: 'dark' | 'light';
  components: {
    user: boolean;
  };
  user: {
    id: string;
    agencyPlayerId: string;
    displayName: string;
    balance: number;
    currency: string;
    photoURL: string;
    coverUrl: string;
    country: string;
  };
  games?: { id: string; status: number }[];
  authQueryString: any;
  wallets: UserWallet[];
  refreshBalance?: () => void;
  onSelect?: (wallet: UserWallet) => void;
}) {
  const { t } = useTranslation();
  const {
    loading,
    loadingBalance,
    colorScheme,
    components,
    user,
    wallets,
    games,
    authQueryString,
  } = props;

  const settings = useSettingsContext();
  const { mode, setMode } = useColorScheme();

  const sendMessageToParent = () => {
    const body = document.body;
    const contentHeight = body.scrollHeight;

    window.parent?.postMessage({ contentHeight }, '*');
  };

  useEffect(() => {
    sendMessageToParent();

    window.addEventListener('load', sendMessageToParent);
    window.addEventListener('resize', sendMessageToParent);
    window.addEventListener('orientationchange', () => {
      setTimeout(sendMessageToParent, 250);
    });

    const observer = new MutationObserver(sendMessageToParent);
    observer.observe(document.body, { childList: true, subtree: true, attributes: true });

    return () => {
      window.removeEventListener('load', sendMessageToParent);
      window.removeEventListener('resize', sendMessageToParent);
      window.removeEventListener('orientationchange', sendMessageToParent);

      observer.disconnect();
    };
  }, []);

  // set UI mode on component did mount only
  useEffect(() => {
    settings.onUpdateField('colorScheme', colorScheme);
    settings.onUpdateField('compactLayout', true);
    setMode(colorScheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorScheme]);

  const { currentLang } = useTranslate();
  const lang = mapCountryCodeToLang(currentLang.value);

  // https://cgame.notion.site/Appendix-01726b3fff9b4d178e69031298852e18#ea6a64720830443b80a1cdd673fafe7f
  const AvailableGames: Record<
    string,
    {
      title: string;
      games: Game[];
    }
  > = {
    Trading: {
      title: t('portal.lobby.game.category.crypto'),
      games: [
        {
          id: GameID.C_FUTURE,
          title: t('portal.lobby.game.info.C_FUTURE'),
          category: t('portal.lobby.game.category.crypto'),
          imageUrl: getGameBannerUrl(GameID.C_FUTURE, lang),
          appUrl: GameUrlMap.C_FUTURE,
          active: games?.find((game) => game.id === GameID.C_FUTURE)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.C_BO,
          title: t('portal.lobby.game.info.C_BO'),
          category: t('portal.lobby.game.category.crypto'),
          imageUrl: getGameBannerUrl(GameID.C_BO, lang),
          appUrl: GameUrlMap.C_BO,
          active: games?.find((game) => game.id === GameID.C_BO)?.status === GAME_ACTIVE_STATUS,
        },
      ],
    },

    Hash: {
      title: t('portal.lobby.game.category.blockchain'),
      games: [
        {
          id: GameID.H_BS,
          title: t('portal.lobby.game.info.H_BS'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_BS, lang),
          appUrl: GameUrlMap.H_BS,
          active: games?.find((game) => game.id === GameID.H_BS)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_BP,
          title: t('portal.lobby.game.info.H_BP'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_BP, lang),
          appUrl: GameUrlMap.H_BP,
          active: games?.find((game) => game.id === GameID.H_BP)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_OE,
          title: t('portal.lobby.game.info.H_OE'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_OE, lang),
          appUrl: GameUrlMap.H_OE,
          active: games?.find((game) => game.id === GameID.H_OE)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_NIUNIU,
          title: t('portal.lobby.game.info.H_NIUNIU'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_NIUNIU, lang),
          appUrl: GameUrlMap.H_NIUNIU,
          active: games?.find((game) => game.id === GameID.H_NIUNIU)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_LUCKY,
          title: t('portal.lobby.game.info.H_LUCKY'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_LUCKY, lang),
          appUrl: GameUrlMap.H_LUCKY,
          active: games?.find((game) => game.id === GameID.H_LUCKY)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_ROCKET,
          title: t('portal.lobby.game.info.H_ROCKET'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_ROCKET, lang),
          appUrl: GameUrlMap.H_ROCKET,
          active: games?.find((game) => game.id === GameID.H_ROCKET)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_TSHOW,
          title: t('portal.lobby.game.info.H_TSHOW'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_TSHOW, lang),
          appUrl: GameUrlMap.H_TSHOW,
          active: games?.find((game) => game.id === GameID.H_TSHOW)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_PLINKO,
          title: t('portal.lobby.game.info.H_PLINKO'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_PLINKO, lang),
          appUrl: GameUrlMap.H_PLINKO,
          active: games?.find((game) => game.id === GameID.H_PLINKO)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_HILO,
          title: t('portal.lobby.game.info.H_HILO'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_HILO, lang),
          appUrl: GameUrlMap.H_HILO,
          active: games?.find((game) => game.id === GameID.H_HILO)?.status === GAME_ACTIVE_STATUS,
        },
        {
          id: GameID.H_BINGO,
          title: t('portal.lobby.game.info.H_BINGO'),
          category: t('portal.lobby.game.category.blockchain'),
          imageUrl: getGameBannerUrl(GameID.H_BINGO, lang),
          appUrl: GameUrlMap.H_BINGO,
          active: games?.find((game) => game.id === GameID.H_BINGO)?.status === GAME_ACTIVE_STATUS,
        },
      ],
    },
  };

  const handleReturn = () => {
    if (isLoadedByIframe()) {
      IframeCommunication.sendToParent({ event: IframeCommunicationEvent.GO_BACK, params: {} });
      return;
    }

    window.close();
  };

  return (
    <DashboardContent
      maxWidth={'lg'}
      sx={{
        py: 4,
      }}
    >
      {components.user && (
        <Card sx={{ mb: 3, height: 290, zIndex: 1, position: 'relative' }}>
          <ProfileCover
            /* eslint-disable-next-line jsx-a11y/aria-role */
            role={`${fNumber(user?.balance)} ${user?.currency}`}
            id={user?.agencyPlayerId}
            name={user?.displayName}
            avatarUrl={user?.photoURL}
            coverUrl={user?.coverUrl}
            loading={loading}
            loadingBalance={loadingBalance}
            wallets={wallets}
            refreshBalance={props.refreshBalance}
            onSelect={props.onSelect}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              left: { xs: 10, md: 24 },
              cursor: 'pointer',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
              }}
              onClick={handleReturn}
            >
              <FaChevronLeft />
              <Typography fontSize={'1.1rem'}>{t('portal.lobby.navigation.back')}</Typography>
            </Box>
          </Box>
        </Card>
      )}

      {Object.values(AvailableGames).map((c) => (
        <Games
          key={c.title}
          categoryTitle={c.title}
          games={c.games}
          loading={loading}
          authQueryString={authQueryString}
        />
      ))}

      <SessionExpiredDialog
        expired_at={
          !!authQueryString.expired_at ? authQueryString.expired_at : Date.now() / 1000 + 10 * 60 // default is 10 mins
        }
      />
    </DashboardContent>
  );
}

function SessionExpiredDialog({ expired_at }: { expired_at: number }) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let ttlInSeconds = expired_at - Math.floor(Date.now() / 1000);
    console.log('{ttlInSeconds} ttlInSeconds, expired_at: ', ttlInSeconds, expired_at);
    if (ttlInSeconds <= 0) ttlInSeconds = 0;

    const tid = setTimeout(() => {
      setVisible(true);
    }, ttlInSeconds * 1000);

    return () => {
      clearTimeout(tid);
    };
  }, [expired_at]);

  return (
    <Dialog
      open={visible}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('portal.lobby.error.sessionExpired.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('portal.lobby.error.sessionExpired.msg')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={undefined} autoFocus disabled variant="outlined">
          {t('portal.lobby.error.sessionExpired.btnText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const TransparentImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
function Games(props: {
  loading: boolean;
  games: Game[];
  categoryTitle: string;
  authQueryString: Record<string, any>;
}) {
  const { t } = useTranslation();
  const { games, loading, authQueryString, categoryTitle } = props;
  // const theme = useTheme();

  const playGame = useCallback(
    (game: Game) => {
      // delay a little bit to show ripple effect, equal to ripple anim time
      setTimeout(() => {
        const gameUrl = game.appUrl.replace(
          /^(https?:\/\/)([^/]+)(\/?.*)$/,
          (_: string, protocol: string, host: string, path: string) => {
            const currentDomain = window.location.hostname.split('.').slice(-2).join('.');
            const subdomain = host.split('.')[0];
            return `${protocol}${subdomain}.${currentDomain}${path}`;
          }
        );
        const appUrl = stringifyUrl({ url: gameUrl, query: authQueryString });
        window.open(appUrl, '_blank');
      }, 400);
    },
    [authQueryString]
  );

  return (
    <>
      <Typography variant="h4" sx={{ mb: 3, mt: 6 }}>
        {categoryTitle}
      </Typography>

      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' }}
      >
        {games.map((game) => {
          const noClick = !game.active || loading;

          return (
            <Card
              key={game.id}
              data-id={`game_${game.id}`}
              sx={{
                color: 'common.white',
                cursor: noClick ? undefined : 'pointer',
                borderRadius: '20px',
              }}
            >
              {!game.active ? (
                <Button
                  color="inherit"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 4,
                    zIndex: 9,
                  }}
                  disabled
                >
                  <Iconify icon="eva:stop-circle-outline" /> &nbsp;
                  {t('portal.lobby.button.maintenance')}
                </Button>
              ) : (
                <Button
                  color="inherit"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 4,
                    zIndex: 9,
                  }}
                  onClick={() => playGame(game)}
                  // variant={'outlined'}
                  disabled={loading}
                >
                  {/* <Iconify icon="eva:play-circle-fill" /> &nbsp; {t('lobby.play')} */}
                  <Iconify icon="eva:play-circle-fill" /> &nbsp; {t('portal.lobby.button.play')}
                </Button>
              )}

              {
                // <ListItemText
                //   sx={{
                //     p: 2,
                //     left: 0,
                //     width: 1,
                //     bottom: 0,
                //     zIndex: 9,
                //     position: 'absolute',
                //   }}
                //   primary={
                //     !loading ? game.title : <Skeleton variant="text" sx={{ fontSize: '2em' }} />
                //   }
                //   // secondary={`${t('lobby.cate')}: ${game.category}`}
                //   secondary={
                //     !loading ? game.category : <Skeleton variant="text" sx={{ fontSize: '1em' }} />
                //   }
                //   primaryTypographyProps={{ noWrap: false, typography: 'subtitle1' }}
                //   secondaryTypographyProps={{
                //     mt: 0.5,
                //     color: 'inherit',
                //     component: 'span',
                //     typography: 'body2',
                //     sx: { opacity: 0.48 },
                //   }}
                // />
              }

              {loading ? (
                <Box sx={{ p: 2, pt: 5 }} style={{ aspectRatio: '3/4' }}>
                  <Skeleton variant="rounded" height={'50%'} />
                </Box>
              ) : (
                <Image
                  alt="gallery"
                  ratio="3/4"
                  src={!loading ? game.imageUrl : TransparentImage}
                  // slotProps={{
                  //   overlay: {
                  //     // background: `linear-gradient(to bottom, ${varAlpha(theme.vars.palette.grey['900Channel'], 0)} 0%, ${theme.vars.palette.grey[900]} 80%)`,
                  //     background: `linear-gradient(180deg,
                  //     rgba(0, 0, 0, 1) 0%,
                  //     rgba(0, 0, 0, 0) 20%,
                  //     rgba(0, 0, 0, 0) 65%,
                  //     rgba(0, 0, 0, 0.68) 80%,
                  //     rgba(0, 0, 0, 1) 100%
                  //   )`,
                  //   },
                  // }}
                />
              )}

              <ButtonBase
                key={game.id}
                component="div"
                sx={{ borderRadius: 2 }}
                onClick={noClick ? undefined : () => playGame(game)}
                disabled={noClick}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                }}
              />
            </Card>
          );
        })}
      </Box>
    </>
  );
}
